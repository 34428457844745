@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.animated-list {
  display: flex;
  animation: scroll 10s linear infinite;
  z-index: 1;
}

.animated-list .list-item {
  flex: 0 0 auto;
}